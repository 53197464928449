import React, { useState } from 'react';
import axios from 'axios';
import _get from 'lodash/get';
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _isNull from 'lodash/isNull';
import _isNil from 'lodash/isNil';
import SEO from '../SEO';
import Layout from '../Layout';
import Avatar from '../Avatar';

import commonPageStyles from '../../styles/commonPage.module.scss';
import styles from './contact.module.scss';

const Contact = () => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);
  const [messageStatus, setMessageStatus] = useState(null);

  const handleFieldInput = (action, errorAction, value) => {
    action(value);

    if (messageError || !_isNull(messageStatus)) {
      setMessageError(false);
      setMessageStatus(null);
    }

    if (!_isNil(errorAction)) {
      errorAction(false);
    }
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const validateFields = () => {
    let isValid = true;

    if (_size(_trim(name)) < 1) {
      setNameError(true);
      isValid = false;
    }

    if (_size(_trim(email)) < 1) {
      setEmailError(true);
      isValid = false;
    }

    if (_size(_trim(message)) < 1) {
      setMessageError(true);
      isValid = false;
    }

    // Validate actual email address
    if (!emailError) {
      const emailRegex = /^.+@.+\..+$/;
      if (!emailRegex.test(_trim(email))) {
        setEmailError(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const onSubmit = async () => {
    const isValid = validateFields();
    if (!isValid) {
      return false;
    }
    setMessageStatus('In Progress');
    const messageData = {
      name: _trim(name),
      email: _trim(email),
      message,
    };
    try {
      const result = await axios.post('/.netlify/functions/message', messageData);
      if (_get(result, 'data.success')) {
        setMessageStatus('Done');
      }
      clearForm();
    } catch (error) {
      setMessageStatus('Error');
    }
  };

  const renderContactText = () => (
    <p>
      You can reach out to us via Instagram{' '}
      <a
        href="https://www.instagram.com/autismparentingjourney/"
        target="_blank"
        rel="noopener noreferrer"
      >
        @autismparentingjourney
      </a>{' '}
      or the contact form below. We will respond as soon as we can. We look forward to hearing
      from&nbsp;you!
    </p>
  );

  const renderAvatars = () => (
    <div className={styles.avatars}>
      <div className={styles.avi}>
        <Avatar author="emily" size="large" />
        <p className={styles.name}>Emily</p>
      </div>
      <div className={styles.avi}>
        <Avatar author="blake" size="large" />
        <p className={styles.name}>Blake</p>
      </div>
    </div>
  );

  const renderForm = () => (
    <div className={styles.formContainer}>
      <input
        type="text"
        placeholder="Name*"
        onChange={e => handleFieldInput(setName, setNameError, e.target.value)}
        value={name}
        className={nameError ? styles.errorBorder : styles.validBorder}
      />
      <input
        type="text"
        placeholder="Email*"
        onChange={e => handleFieldInput(setEmail, setEmailError, e.target.value)}
        value={email}
        className={emailError ? styles.errorBorder : styles.validBorder}
      />
      <textarea
        placeholder="Message*"
        onChange={e => handleFieldInput(setMessage, setMessageError, e.target.value)}
        value={message}
        className={messageError ? styles.errorBorder : styles.validBorder}
      />
      <button
        type="button"
        className={styles.sendButton}
        onClick={onSubmit}
        aria-label="Send Message"
      >
        Send Message
      </button>

      <div className={styles.progressContainer}>
        {messageStatus === 'In Progress' && <span>Sending Message...</span>}
        {messageStatus === 'Done' && (
          <span>
            <span className={styles.success}>&#10004;&nbsp;</span>
            Message Sent!
          </span>
        )}
        {messageStatus === 'Error' && (
          <span className={styles.errorText}>Error sending message. Try again&nbsp;later.</span>
        )}
      </div>
    </div>
  );

  const pageDescription =
    'You can reach out to us via Instagram @autismparentingjourney or the contact form below. We will respond as soon as we can. We look forward to hearing from you!';

  return (
    <Layout>
      <SEO
        title="Contact Us | Autism Parenting Journey"
        pathname="/contact"
        description={pageDescription}
      />
      <section className={commonPageStyles.pageWrapper}>
        <div className={commonPageStyles.mainContent}>
          <div className={commonPageStyles.sectionHeaderWrapper}>
            <h1 className={commonPageStyles.sectionHeader}>Contact Us</h1>
          </div>
          <div className={styles.contactWrapper}>
            {renderContactText()}
            {renderAvatars()}
            {renderForm()}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
